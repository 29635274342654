import * as React from "react";

import RenderButton from "../RenderButton";
import AuthContext from "../../../store/auth-context";
import { useDispatch, useSelector } from "react-redux";
import { validUserInputRegex } from "../../Common/ValidInput";

import { addBestellungInput, removeBestellungInput } from "../../../store/redux-store";


const KFW_ES_24Step1 = ({
  handleActiveStepChange,
  currentPage,
}) => {
  const authCtx = React.useContext(AuthContext);

  const dispatch = useDispatch();

  const apiUrl = process.env.REACT_APP_API_HOST
    ? process.env.REACT_APP_API_HOST
    : "https://sfpapi.videocreator.net";

  //Bankname vorgelegen
  const field = "locationTag";

  const [initialLoad, setInitialLoad] = React.useState(true);

  let prevUserInput = useSelector((state) => state.userInput[field]);

  let prevHintergrund = useSelector((state) => state.userInput["verortungID"]);

  const [content, setContent] = React.useState(prevUserInput);


  const [hintergrund, setHintergrund] = React.useState(prevHintergrund || "01");


  const onChangeHandler = (event) => {

    if (!validUserInputRegex.test(event.target.value)) {
      return;
    }

    let inputLines = event.target.value.match(/\n/g) || [];

    let count = inputLines.length;
    let modValue = event.target.value;
    while (count >= 4) {
      const lastLinebreak = modValue.lastIndexOf("\n");
      modValue = modValue.substring(0, lastLinebreak);
      count = (modValue.match(/\n/g) || []).length;
    }

    setContent(modValue);

    dispatch({
      type: "addUserInput",
      payload: {
        value: modValue.trim(),
        field: field,
      },
    });

    //bestellunginput
    dispatch(addBestellungInput("Ortsname aus Geschäftsgebiet", modValue.trim()));
  };


  /*
    const fetchName = React.useCallback(async () => {
      const res = await fetch(apiUrl + "/userinput/bankname", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      });
      const name = await res.text();
  
      //console.log(name);
      setName(name);
  
      dispatch({
        type: "addUserInput",
        payload: {
          value: name.trim(),
          field: field,
        },
      });
  
      dispatch(addBestellungInput("Ortsname aus Geschäftsgebiet", name.trim()));
  
  
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    */


  const handleToggle = (value) => {

    setHintergrund(value);

    dispatch({
      type: "addUserInput",
      payload: {
        value: value,
        field: "verortungID",
      },
    });


    dispatch(addBestellungInput("Hintergrund", value === "01" ? "urban" : "ländlich"));
    if (content) {
      dispatch(addBestellungInput("Ortsname aus Geschäftsgebiet", content.trim()));
    }

  };


  React.useEffect(() => {
    if (initialLoad) {
      if (!prevUserInput) {
        dispatch(addBestellungInput("Ortsname aus Geschäftsgebiet", "Musterstadt"));
      }
      if (!prevHintergrund) {
        dispatch(addBestellungInput("Hintergrund", hintergrund === "01" ? "urban" : "ländlich"));
      }
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevUserInput, prevHintergrund, hintergrund, initialLoad]);

  return (
    <div className={"Step C2Step2 c-Logoauswahl"}>
      <div className={"content"}>
        <h4>
          1. Trage einen Ortsnamen aus deinem Geschäftsgebiet mit korrekten Zeilenumbrüchen ein, der auf dem Schild dargestellt wird:
        </h4>

        <div>
          <textarea
            className={"e-textarea"}
            key="0"
            width="95"
            onChange={onChangeHandler}
            rows="3"
            placeholder="Musterstadt"
            value={content}
            style={{ maxLines: 4 }}
          ></textarea>
        </div>

        <h4>
          2. Wählen Sie einen Hintergrund passend für Ihre Geschäftsgebiet:
        </h4>

        <div className="flex flex-col gap-2">
          <div>
            <input
              type="radio"
              id="urban"
              name="background"
              value="01"
              onChange={(e) => handleToggle(e.target.value)}
              checked={hintergrund === '01'}
            />
            <label htmlFor="urban" className="ml-2">urban</label>
          </div>
          <div>
            <input
              type="radio"
              id="laendlich"
              name="background"
              value="02"
              onChange={(e) => handleToggle(e.target.value)}
              checked={hintergrund === '02'}
            />
            <label htmlFor="laendlich" className="ml-2">ländlich</label>
          </div>
        </div>


        <div className={"c-ButtonBar"}>
          <div
            className={"e-button gray"}
            onClick={() => {
              handleActiveStepChange(currentPage - 1);
            }}
          >
            Zurück
          </div>

          <RenderButton
            maxWidth="600px"
            key="1"
            campaignuuid="887ee49c-ef07-418e-bb45-af3523478c9c"
            weiterAction={handleActiveStepChange}
            weiterActionProp={currentPage + 1}
          />

        </div>
      </div>

      <div className={"content-sidebar"}>
        <div className={"big-image"}>
          <img src='https://dev2.videocreator.net/media/assets/KFW_Energ_Sanieren/locationTagUrban.jpg' alt='Beispielbild ländlich.jpg' />

          <p>
            <b>Beispielbild urban</b>
          </p>
        </div>
        <div className={"big-image"}>
          <img src='https://dev2.videocreator.net/media/assets/KFW_Energ_Sanieren/locationTagLaendlich.jpg' alt='Beispielbild urban.jpg' />

          <p>
            <b>Beispielbild ländlich</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default KFW_ES_24Step1;
