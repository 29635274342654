import * as React from "react";
import ReactPlayer from "react-player";
import "./CampaignKFW_ES_24.scss"

import AuthContext from "../../../store/auth-context";


const KFW_ES_24Step0 = ({ campaignData, handleActiveStepChange, setBestellstrecke }) => {
  campaignData.text = "";
  // das hier sollte von der api kommen und dann hier nicht gemacht werden
  const authCtx = React.useContext(AuthContext);
  const authCtxData = JSON.parse(authCtx.data);

  const reactPlayerRef = React.createRef();

  const [videoIndex, setVideoIndex] = React.useState(0);

  const [loopVideo, setLoopVideo] = React.useState(true);

  const [endReached, setEndReached] = React.useState(false);

  // Ref für die Playlist erstellen
  const playlistRef = React.useRef();

  const videoData = [
    { url: "/media/assets/KFW_Energ_Sanieren/01_EF_KfW_Energetisch_Sanieren_120sek_16x9.mp4", title: "01 EF KfW Energetisch Sanieren 120sek 16x9" },
    { url: "/media/assets/KFW_Energ_Sanieren/02_EF_KfW_Energetisch_Sanieren_60sek_16x9.mp4", title: "02 EF KfW Energetisch Sanieren 60sek 16x9" },
    { url: "/media/assets/KFW_Energ_Sanieren/03_EF_KfW_Energetisch_Sanieren_60sek_9x16.mp4", title: "03 EF KfW Energetisch Sanieren 60sek 9x16" },
    { url: "/media/assets/KFW_Energ_Sanieren/04_EF_KfW_Energetisch_Sanieren_60sek_16x9_POV.mp4", title: "04 EF KfW Energetisch Sanieren 60sek 16x9 POV" },
    { url: "/media/assets/KFW_Energ_Sanieren/05_EF_KfW_Energetisch_Sanieren_60sek_9x16_POV.mp4", title: "05 EF KfW Energetisch Sanieren 60sek 9x16 POV" },
    { url: "/media/assets/KFW_Energ_Sanieren/06_EF_KfW_Energetisch_Sanieren_15sek_9x16.mp4", title: "06 EF KfW Energetisch Sanieren 15sek 9x16 (ohne Ton)" }
  ];

  const nextVideo = React.useCallback(() => {
    if (videoIndex === videoData.length - 1) {

      setEndReached(true);

      setVideoIndex(0);

    } else {
      setVideoIndex(Number(videoIndex) + 1);
      setEndReached(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoIndex]);

  const changeVideo = React.useCallback(() => {

    reactPlayerRef.current.getInternalPlayer().src = videoData[videoIndex].url;
    if (endReached) {
      reactPlayerRef.current.getInternalPlayer().pause();
    } else {
      reactPlayerRef.current.getInternalPlayer().play();
    }


    //videoUrls nicht als dependency benutzen
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactPlayerRef, videoIndex, endReached]);

  const toggleLoop = () => {
    setLoopVideo(!loopVideo);
  }

  const setPlayerOnended = React.useCallback(() => {

    if (reactPlayerRef.current?.getInternalPlayer()) {

      let onEnded = () => { };
      if (loopVideo) {
        onEnded = () => nextVideo();
      }

      reactPlayerRef.current.getInternalPlayer().onended = onEnded;
    }

  }, [loopVideo, nextVideo, reactPlayerRef]);

  React.useEffect(() => {
    setPlayerOnended();
  }, [loopVideo, setPlayerOnended]);

  React.useEffect(() => {
    if (reactPlayerRef.current?.getInternalPlayer()) {

      setPlayerOnended();

      changeVideo();
    }

  }, [videoIndex, reactPlayerRef, setPlayerOnended, changeVideo]);



  // Scroll zur aktuellen Position des aktiven Videos
  React.useEffect(() => {
    if (playlistRef.current && playlistRef.current.children[videoIndex]) {
      const activeVideo = playlistRef.current.children[videoIndex];
      activeVideo.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [videoIndex]);

  /*
   <button className="toggle-loop-button" onClick={() => toggleLoop()}>
            {loopVideo ? '\u25A0 Manuell abspielen' : '\u25B6 Automatisch abspielen'}
          </button>
  */

  const updatePlaylistWidth = () => {
    //console.log('updatePlaylistWidth')
    if (reactPlayerRef.current && playlistRef.current) {
      const videoElement = reactPlayerRef.current.getInternalPlayer();
      if (videoElement) {
        //print all properties of videoElement
        // for (var prop in videoElement) {
        //   console.log(prop, videoElement[prop]);
        // }
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;
        const aspectRatio = videoWidth / videoHeight;

        // console.log('videoWidth', videoWidth)
        // console.log('videoHeight', videoHeight)
        // console.log('aspectRatio', aspectRatio)

        const wrapper = reactPlayerRef.current.wrapper;
        const containerHeight = wrapper.getBoundingClientRect().height;
        const actualVideoWidth = containerHeight * aspectRatio;

        // console.log('containerHeight', containerHeight)
        // console.log('actualVideoWidth', actualVideoWidth)

        // console.log('current width', playlistRef.current.style.width)
        playlistRef.current.style.width = `${actualVideoWidth}px`;
        // console.log('new width', playlistRef.current.style.width)

      }
    }
  };

  const handleBestellstreckeChange = (value) => {
    setBestellstrecke(value);
  };

  React.useEffect(() => {
    updatePlaylistWidth();
  }, [reactPlayerRef, playlistRef]);

  const kostenMap = {
    "XXS": "199,00 Euro",
    "XS": "249,00 Euro",
    "S": "311,00 Euro",
    "M": "389,00 Euro",
    "L": "486,00 Euro",
    "XL": "607,00 Euro",
  };

  const bilanzKosten = kostenMap[authCtxData?.groesse];

  return (
    <div className={"Step C2Step0 c-Uebersicht"}>
      <div className={"big-video"}>
        <ReactPlayer
          ref={reactPlayerRef}
          url={videoData[videoIndex].url}
          controls={true}
          muted={true}
          onPlay={() => setPlayerOnended()}
          onReady={updatePlaylistWidth}
        />

        <div className="playlist-container" ref={playlistRef}>
          <div className="playlist-title">Playlist</div>

          <div className="video-list">
            {videoData.map((video, index) => (
              <div
                key={index}
                className={`video-item${videoIndex === index ? ' active' : ''}`}
                onClick={() => setVideoIndex(index)}
              >
                {video.thumbnail && <img src={video.thumbnail} alt={`Video ${index + 1}`} />}
                <div className="video-text">
                  <div className="video-title">{video.title}</div>
                  {video.description && <div className="video-description">{video.description}</div>}
                </div>
              </div>
            ))}
          </div>

        </div>

      </div>
      <div className={"content c-KampagnenBeschreibung"}>
        <h5 style={{ color: "grey" }}>Veröffentlicht am 29.11.2024</h5>
        <br />
        <h4>Beschreibung</h4>
        <p>Sie erhalten das komplette Videopaket der KfW Energetisch Sanieren Kampagne individualisiert mit Ihrem Institutslogo.
        </p>

        <div
          className={"e-button"}
          onClick={() => {
            handleActiveStepChange(1);
            handleBestellstreckeChange(true);
          }}
        >
          Bestellung starten
        </div>


        <br />
        <div className="h-line"></div>
        <h4>Details: </h4>
        <ul>
          <li>Einsetzen des Logos Ihrer Sparkasse in alle Abbinder-Animationen</li>
          <li>Ortsschild als individualisierbares Element</li>
          <li>Auswahl zwischen urbanem und ländlichem Hintergrund</li>
          <li>
            Lieferung:
            <div class="scrollable-list">
              <ul>
                <li>01 EF KfW Energetisch Sanieren 120sek 16x9</li>
                <li>02 EF KfW Energetisch Sanieren 60sek 16x9</li>
                <li>03 EF KfW Energetisch Sanieren 60sek 9x16</li>
                <li>04 EF KfW Energetisch Sanieren 60sek 16x9 POV</li>
                <li>05 EF KfW Energetisch Sanieren 60sek 9x16 POV</li>
                <li>06 EF KfW Energetisch Sanieren 15sek 9x16 (ohne Ton)</li>
              </ul>
            </div>
          </li>
          <li>Es gelten für die individualisierten Medien die gleichen zentralen Nutzungsrechte der Kampagne, welche in der Kommunikationswelt-Planung (<a href="https://planung.kommunikationswelt.dsv-gruppe.de">https://planung.kommunikationswelt.dsv-gruppe.de</a>) einzusehen sind.</li>
        </ul>
        <div className="h-line"></div>
        <h4>Kosten: </h4>
        <p>Die Kosten sind nach Bilanzsumme gestaffelt:</p>
        {bilanzKosten && (
          <p>{authCtxData?.bilanzsumme} : <b>{bilanzKosten}</b> zzgl. MwSt.</p>
        )}

      </div>
    </div>
  );
};

export default KFW_ES_24Step0;
