import * as React from "react";

import RenderButton from "../RenderButton";
import AuthContext from "../../../store/auth-context";
import { useDispatch, useSelector } from "react-redux";
import { validUserInputRegex } from "../../Common/ValidInput";

import { addBestellungInput, removeBestellungInput } from "../../../store/redux-store";

const KFW_ES_24Step2 = ({
  handleActiveStepChange,
  currentPage,
}) => {
  const authCtx = React.useContext(AuthContext);

  const dispatch = useDispatch();

  const apiUrl = process.env.REACT_APP_API_HOST
    ? process.env.REACT_APP_API_HOST
    : "https://sfpapi.videocreator.net";

  //Bankname vorgelegen
  const field = "institutText";

  let prevUserInput = useSelector((state) => state.userInput[field]);
  const [content, setContent] = React.useState(prevUserInput);

  const [name, setName] = React.useState("");



  const onChangeHandler = (event) => {

    if (!validUserInputRegex.test(event.target.value)) {
      return;
    }

    let inputLines = event.target.value.match(/\n/g) || [];

    let count = inputLines.length;
    let modValue = event.target.value;
    while (count >= 4) {
      const lastLinebreak = modValue.lastIndexOf("\n");
      modValue = modValue.substring(0, lastLinebreak);
      count = (modValue.match(/\n/g) || []).length;
    }

    setContent(modValue);

    dispatch({
      type: "addUserInput",
      payload: {
        value: modValue.trim(),
        field: field,
      },
    });

    //bestellunginput
    dispatch(addBestellungInput("Institutsnamen mit Zeilenumbrüchen", modValue.trim()));
  };

  //Sonderlogo vorgelegen
  let campaignUUID = useSelector((state) => state.campaign.uuid);
  const fieldLogo = "sonderlogoBild";

  const [standardLogos, setStandardLogos] = React.useState([]);
  const [sonderLogos, setSonderLogos] = React.useState([]);

  let prevSelectedImage = useSelector((state) => state.userInput[fieldLogo]) || "";

  let prevSelectedImageObj = sonderLogos.find((logo) => logo.link === prevSelectedImage);
  if (!prevSelectedImageObj) {
    prevSelectedImageObj = standardLogos.find((logo) => logo.link === prevSelectedImage);
  }

  const [selectedImage, setSelectedImage] = React.useState(prevSelectedImageObj || undefined);

  const fetchName = React.useCallback(async () => {
    const res = await fetch(apiUrl + "/userinput/bankname", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    });
    const name = await res.text();

    //console.log(name);
    setName(name);

    dispatch({
      type: "addUserInput",
      payload: {
        value: name.trim(),
        field: field,
      },
    });

    //bestellunginput
    //if logo is not sonderlogo
    if (!standardLogos || !selectedImage || standardLogos.find((logo) => logo.link === selectedImage.link)) {
      dispatch(addBestellungInput("Institutsnamen mit Zeilenumbrüchen", name.trim()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = (value) => () => {

    setSelectedImage(value);
    dispatch({
      type: "addUserInput",
      payload: {
        value: value.link,
        field: fieldLogo,
      },
    });


    dispatch(addBestellungInput("Logo-Design", "vertikal"));
    if (content) {
      dispatch(addBestellungInput("Institutsnamen mit Zeilenumbrüchen", content.trim()));
    }

    let sonderlogotext = "";
    if (!value.standard) {
      sonderlogotext = "x";
      dispatch({
        type: "addUserInput",
        payload: {
          value: sonderlogotext,
          field: "sonderlogoText",
        },
      });

      //bestellunginput value => value link => filename

      dispatch(addBestellungInput("Sparkassen-Sonderlogo", value.link.split("/").pop()));
      dispatch(removeBestellungInput("Logo-Design"));
      dispatch(removeBestellungInput("Institutsnamen mit Zeilenumbrüchen"));

    } else {

      dispatch({
        type: "removeUserInput",
        payload: {
          field: "sonderlogoText",
        },
      });

      dispatch(removeBestellungInput("Sparkassen-Sonderlogo"));

      let design;

      if (value.link !== standardLogos[1].link) {
        dispatch({
          type: "removeUserInput",
          payload: {
            field: "institutAlt",
          },
        });
        design = 'vertikal'
      } else {
        dispatch({
          type: "addUserInput",
          payload: {
            value: "y",
            field: "institutAlt",
          },
        });
        design = 'horizontal'
      }
      dispatch(addBestellungInput("Logo-Design", design));
    }
  };


  React.useEffect(() => {

    if (!prevUserInput) {
      //fetchLogos();
      fetchName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevUserInput]);

  return (
    <div className={"Step C2Step2 c-Logoauswahl"}>
      <div className={"content"}>
        <h4>
          1. Trage den Institutsnamen der Sparkasse mit korrekten Zeilenumbrüchen in das Textfeld ein:
        </h4>

        <div>
          <textarea
            className={"e-textarea"}
            key="0"
            width="95"
            onChange={onChangeHandler}
            rows="3"
            defaultValue={name}
            value={content}
            style={{ maxLines: 4 }}
          ></textarea>
        </div>

        <div className={"c-ButtonBar"}>
          <div
            className={"e-button gray"}
            onClick={() => {
              handleActiveStepChange(currentPage - 1);
            }}
          >
            Zurück
          </div>

          <RenderButton
            maxWidth="600px"
            key="1"
            campaignuuid="38855866-03cc-4584-aa64-234551ae73f5"
            weiterAction={handleActiveStepChange}
            weiterActionProp={currentPage + 1}
          />
        </div>
      </div>

      <div className={"content-sidebar"}>
        <div className={"big-image"}>
          <img src='https://dev2.videocreator.net/media/assets/KFW_Energ_Sanieren/institutLogo.jpg' alt='institutLogo.jpg' />

          <p>
            <b>Beispielbild</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default KFW_ES_24Step2;
