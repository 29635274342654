

import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthContext from "../../../store/auth-context";

import KFW_ES_24Step0 from "./KFW_ES_24Step0"
import KFW_ES_24Step2 from "./KFW_ES_24Step2"
import KFW_ES_24Step3 from "./KFW_ES_24Step3"
import KFW_ES_24Step4 from "./KFW_ES_24Step4"

import Bestellstrecke from "../../Bestellstrecke/Bestellstrecke";
import KFW_ES_24Step5 from "./KFW_ES_24Step5";
import "./CampaignKFW_ES_24.scss";
import KFW_ES_24Step1 from "./KFW_ES_24Step1";


function getSteps(campaignData, activeStep, bestellstrecke) {
  if (activeStep === 0) {
    return ['Übersicht'];
  }

  if (campaignData.hasBestellstrecke && bestellstrecke) {
    //campaignData.bestellstrecke.pages.headline --> Liste
    const headlines = campaignData.bestellstrecke.bestellstrecke.pages.map((page) => page.headline);
    return ["Übersicht", ...headlines, "Verortung", "Sparkassen-Logo", "Bestellung", "Nutzungsbedingungen", "Abschluss"];
  }

  return ["Übersicht", "Verortung", "Sparkassen-Logo", "Nutzungsbedingungen", "Abschluss"];
}


function Step({ campaignData, activeStep, handleActiveStepChange, handleReset, bestellstrecke, setBestellstrecke }) {
  const headlines = bestellstrecke && campaignData?.bestellstrecke?.bestellstrecke?.pages.length || 0;

  //console.log("activeStep", activeStep);
  //console.log("headlines", headlines);
  if (activeStep === 0) {

    return (<KFW_ES_24Step0 campaignData={campaignData} handleActiveStepChange={handleActiveStepChange} bestellstrecke={bestellstrecke} setBestellstrecke={setBestellstrecke} />);
  }
  else if (bestellstrecke && (activeStep > 0 && activeStep <= headlines)) {
    if (activeStep === headlines + 1) {
      return (<KFW_ES_24Step3 campaignData={campaignData} handleActiveStepChange={handleActiveStepChange} handleReset={handleReset} currentPage={activeStep} bestellstrecke={bestellstrecke} />);
    }

    return <Bestellstrecke bestellstrecke={campaignData.bestellstrecke.bestellstrecke} currentPage={activeStep} handleActiveStepChange={handleActiveStepChange} campaignuuid={campaignData.uuid} redirectHref={campaignData.frontendLink} bestellButton={false} />;

  } else if (bestellstrecke && activeStep === headlines + 1 || activeStep == 1) {

    return (<KFW_ES_24Step1 campaignData={campaignData} handleActiveStepChange={handleActiveStepChange} currentPage={activeStep} />);
  }
  else if (bestellstrecke && activeStep === headlines + 2 || activeStep == 2) {

    return (<KFW_ES_24Step2 campaignData={campaignData} handleActiveStepChange={handleActiveStepChange} currentPage={activeStep} />);
  }
  else if (bestellstrecke && activeStep === headlines + 3) {
    return <Bestellstrecke bestellstrecke={campaignData.bestellstrecke.bestellstrecke} currentPage={activeStep} handleActiveStepChange={handleActiveStepChange} campaignuuid={campaignData.uuid} redirectHref={campaignData.frontendLink} bestellButton={false} navigateButton={true} />;
  }

  else if (bestellstrecke && activeStep === headlines + 4 || activeStep == 4) {
    return (<KFW_ES_24Step4 campaignData={campaignData} handleActiveStepChange={handleActiveStepChange} handleReset={handleReset} currentPage={activeStep} bestellstrecke={bestellstrecke} />);
  }


  else {
    return (<KFW_ES_24Step5 />);
  }

}

export default function CampaignGSK_KFW_ES_2424() {
  const [activeStep, setActiveStep] = React.useState(0);

  const [bestellstrecke, setBestellstrecke] = React.useState(false);

  function handleActiveStepChange(newValue) {
    setActiveStep(newValue);
  }

  const campaignData = useSelector((state) => state.campaign);

  //if campaignData is undefined, redirect to /campaigns
  if ((!campaignData || !campaignData.campagneName) && activeStep <= 2) {
    window.location.href = "/kampagnen";
  }
  const steps = getSteps(campaignData, activeStep, bestellstrecke);


  const authCtx = useContext(AuthContext);
  let userInputs = useSelector((state) => state.userInput);
  let bestellungBestellstrecke = useSelector((state) => state.bestellung);
  const dispatch = useDispatch();

  if (process.env.REACT_APP_FTP_OUTPUT) {
    userInputs = {
      ...userInputs,
      ftpOutputFolder: process.env.REACT_APP_FTP_OUTPUT,
    };
  }

  let campaignUUID = useSelector((state) => state.campaign.uuid);

  const bodyText = JSON.stringify({
    campaignuuid: campaignUUID,
    userinputKeyValueInput: JSON.stringify(userInputs),
  });

  const bodyTextBestellung = JSON.stringify({
    campaignuuid: campaignUUID,
    userinputKeyValueInput: JSON.stringify(userInputs),
    bestellung: {
      bestellStrecke: JSON.stringify(bestellungBestellstrecke),
      campaingUUID: campaignUUID,
    }
  });


  const apiUrl = process.env.REACT_APP_API_HOST
    ? process.env.REACT_APP_API_HOST
    : "https://sfpapi.videocreator.net";

  const handleReset = () => {
    //console.log(apiUrl + "/userinput/render");

    //console.log(JSON.stringify(bodyText));

    fetch(apiUrl + "/userinput/render", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
      body: bestellstrecke ? bodyTextBestellung : bodyText,
    });

    dispatch({
      type: "reset",
    });

    setActiveStep(1);
  };




  //const { from } = location.campaign;
  //console.log(from); // output: "the-page-id"

  return (
    <>
      <div className={"page-title"}>
        <div className={"container"}>
          <h2>{campaignData.campagneName} - Video-Individualisierung</h2>
        </div>
      </div>
      <div className={"container"}>

        <div className={"c-stepsNavigation"}>
          {
            steps.map((label, index) =>
              <div key={"step_" + index} className={"step" + (activeStep === index ? " active" : "") + (activeStep > index ? " finished" : "")}>{label}</div>
            )
          }
        </div>


        <Step activeStep={activeStep}
          handleActiveStepChange={handleActiveStepChange}
          campaignData={campaignData} handleReset={handleReset}
          bestellstrecke={bestellstrecke} setBestellstrecke={setBestellstrecke}
        />
      </div>
    </>
  );
}
